import i18n from 'locale/i18n'

export function getAliasDNS() {
   const path = window.location.origin
   const regex = /:\/\/(.*)\.signin/
   const result = path.match(regex)

   return !!result ? result[1] : ''
}

export function handleDisabledButton(errors: Object) {
   return !errors || Object.keys(errors).length > 0
}

export function redirectTo() {
   const { origin } = window.location
   const url = origin.concat('/login')

   // eslint-disable-next-line immutable/no-mutation
   return (window.location.href = url)
}

export function redirectToWithoutAlias(DNS: string) {
   const { origin } = window.location
   const url = origin.concat('/login').replace('signin.', `${DNS}.signin.`)

   // eslint-disable-next-line immutable/no-mutation
   return (window.location.href = url)
}

export function stringToCamelCase(str: string) {
   return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
         return index === 0 ? word.toLowerCase() : word.toUpperCase()
      })
      .replace(/\s+/g, '')
}

export const resolveRequestError = (err: any) => {
   if (!!err && !!err.data && !!err.data.messages && err.data.messages[0]) {
      return err.data.messages[0]
   } else if (!!err && !!err.message) {
      return err.message
   } else {
      return i18n.t('alert-message-error-default')
   }
}
