import React from 'react'
import Routes from 'routes'
import GlobalStyle from 'theme/globalStyle'

import { ToastContainer } from 'sensedia-ui-kit'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AnimationSquared } from 'components/AnimationSquared'

import 'assets/css/app.css'

const queryClient = new QueryClient()

const App = () => (
   <QueryClientProvider client={queryClient}>
      <Routes />
      <ToastContainer />
      <GlobalStyle />
      <AnimationSquared />
   </QueryClientProvider>
)

export default App
