import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Legend, ContainerRoles, IconBox } from './index.styles'
import { TextFormat, Row, Col, Icon } from 'sensedia-ui-kit'

import {
   initialRules,
   Lowercase,
   Numbers,
   PWPoliciesProps,
   RulesProps,
   Symbols,
   Uppercase,
   validateRule,
} from './index.resolvers'

const PasswordPolicies = ({ password, passwordPolicy }: PWPoliciesProps) => {
   const [t] = useTranslation()

   const [rules, setRules] = useState<RulesProps>(initialRules)

   useEffect(() => {
      setRules({
         lowercase: validateRule(password, Lowercase),
         numbers: validateRule(password, Numbers),
         size: password.length >= passwordPolicy.minimumLength,
         symbols: validateRule(password, Symbols),
         uppercase: validateRule(password, Uppercase),
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [password])

   return (
      <ContainerRoles>
         <Legend>{t('password-policies-title')}</Legend>

         {!!passwordPolicy && (
            <Row size={100} style={{ margin: '0 -10px' }}>
               {passwordPolicy.requireLowercase && (
                  <Col sm={6} md={6} lg={6} xl={6} mb={-10}>
                     <TextFormat
                        size={13}
                        svgSize={15}
                        align="left"
                        svgColor="white"
                        color="grayMedium"
                     >
                        <IconBox
                           color={
                              !password
                                 ? 'grayMedium'
                                 : rules.lowercase
                                 ? 'primary'
                                 : 'critical'
                           }
                        >
                           <Icon
                              name={
                                 rules.lowercase
                                    ? 'check-circle'
                                    : 'unhealthy-circle'
                              }
                           />
                        </IconBox>

                        {t('password-policies-label-require-lowercase')}
                     </TextFormat>
                  </Col>
               )}

               {passwordPolicy.requireUppercase && (
                  <Col sm={6} md={6} lg={6} xl={6} mb={-10}>
                     <TextFormat
                        size={13}
                        svgSize={15}
                        align="left"
                        svgColor="white"
                        color="grayMedium"
                     >
                        <IconBox
                           color={
                              !password
                                 ? 'grayMedium'
                                 : rules.uppercase
                                 ? 'primary'
                                 : 'critical'
                           }
                        >
                           <Icon
                              name={
                                 rules.uppercase
                                    ? 'check-circle'
                                    : 'unhealthy-circle'
                              }
                           />
                        </IconBox>

                        {t('password-policies-label-require-uppercase')}
                     </TextFormat>
                  </Col>
               )}

               {passwordPolicy.requireNumbers && (
                  <Col sm={6} md={6} lg={6} xl={6} mb={-10}>
                     <TextFormat
                        size={13}
                        svgSize={15}
                        align="left"
                        svgColor="white"
                        color="grayMedium"
                     >
                        <IconBox
                           color={
                              !password
                                 ? 'grayMedium'
                                 : rules.numbers
                                 ? 'primary'
                                 : 'critical'
                           }
                        >
                           <Icon
                              name={
                                 rules.numbers
                                    ? 'check-circle'
                                    : 'unhealthy-circle'
                              }
                           />
                        </IconBox>

                        {t('password-policies-label-require-numbers')}
                     </TextFormat>
                  </Col>
               )}

               {passwordPolicy.minimumLength && (
                  <Col sm={6} md={6} lg={6} xl={6} mb={-10}>
                     <TextFormat
                        size={13}
                        svgSize={15}
                        align="left"
                        svgColor="white"
                        color="grayMedium"
                     >
                        <IconBox
                           color={
                              !password
                                 ? 'grayMedium'
                                 : rules.size
                                 ? 'primary'
                                 : 'critical'
                           }
                        >
                           <Icon
                              name={
                                 rules.size
                                    ? 'check-circle'
                                    : 'unhealthy-circle'
                              }
                           />
                        </IconBox>

                        {t('password-policies-label-minimum-char', {
                           param: passwordPolicy.minimumLength,
                        })}
                     </TextFormat>
                  </Col>
               )}

               {passwordPolicy.requireSymbols && (
                  <Col sm={6} md={6} lg={6} xl={6} mb={-10}>
                     <TextFormat
                        size={13}
                        svgSize={15}
                        align="left"
                        svgColor="white"
                        color="grayMedium"
                     >
                        <IconBox
                           color={
                              !password
                                 ? 'grayMedium'
                                 : rules.symbols
                                 ? 'primary'
                                 : 'critical'
                           }
                        >
                           <Icon
                              name={
                                 rules.symbols
                                    ? 'check-circle'
                                    : 'unhealthy-circle'
                              }
                           />
                        </IconBox>

                        {t('password-policies-label-require-symbols')}
                     </TextFormat>
                  </Col>
               )}
            </Row>
         )}
      </ContainerRoles>
   )
}

export { PasswordPolicies }
