import React from 'react'
import logo from 'assets/img/logo.svg'

import { redirectTo } from 'utils/resolvers'
import {
   Container,
   ModalDialog,
   Logo,
   Banner,
   ModalContent,
} from './index.styles'

const Layout = ({ children }: React.PropsWithChildren<any>) => {
   return (
      <Container>
         <ModalDialog>
            <ModalContent>
               <Banner>
                  <Logo
                     alt="logo"
                     src={logo}
                     className="logo-customizable logo-link"
                     onClick={() => redirectTo()}
                  />
               </Banner>
            </ModalContent>

            {children}
         </ModalDialog>
      </Container>
   )
}

export default Layout
