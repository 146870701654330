import React from 'react'

export const AnimationSquared = () => {
   return (
      <ul className="bg-bubbles">
         {new Array(10).fill('').map((_, i) => {
            return <li key={`square-${i}`}></li>
         })}
      </ul>
   )
}
