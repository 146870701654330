import * as yup from 'yup'
import i18n from 'locale/i18n'
import { PoliciesRules } from 'components/PasswordPolicies/index.resolvers'

const ResolveValidation = (params: PoliciesRules) => {
   const regexString = `
      ^
      ${params.requireNumbers ? '(?=.*\\d)' : ''}
      ${params.requireLowercase ? '(?=.*[a-z])' : ''}
      ${params.requireUppercase ? '(?=.*[A-Z])' : ''}
      ${
         params.requireSymbols
            ? "(?=.*[@!#~^$+=*.:;|_`,><'".concat('%&?"()}{\\/\\[\\]-])')
            : ''
      }
      .{${params.minimumLength},}$
   `

   const regexWithoutSpace = new RegExp(
      regexString.replace(/(\r\n|\n|\r|\s)/gm, ''),
   )

   const regex = new RegExp(regexWithoutSpace)

   const schema = yup.object().shape({
      name: yup
         .string()
         .max(100, i18n.t('error-validation-max-char', { param: 100 }))
         .required(i18n.t('error-validation-field-required')),
      password: yup
         .string()
         .required(i18n.t('error-validation-field-required'))
         .test({
            message: i18n.t('error-validation-password-rules'),
            test: (value) => Boolean(regex.exec(value)),
         })
         .test({
            message: i18n.t('error-validation-blanks'),
            test: (value) => !!value && !value.match(/\s/),
         }),
      confirmPassword: yup
         .string()
         .required(i18n.t('error-validation-field-required'))
         .oneOf(
            [yup.ref('password'), ''],
            i18n.t('error-validation-password-identical'),
         ),
   })

   return schema
}

export { ResolveValidation }
