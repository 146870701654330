import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import { Loading } from 'sensedia-ui-kit'
import { ModalBody } from 'theme/layout/index.styles'
import { getAliasDNS, redirectTo } from 'utils/resolvers'

export const NotFound = () => {
   const alias = getAliasDNS()
   const { push } = useHistory()

   useEffect(() => {
      !!alias ? redirectTo() : push('/tenant-signin')
   }, [alias, push])

   return (
      <ModalBody>
         <Loading icon="platform" />
      </ModalBody>
   )
}
