import React, { PropsWithChildren } from 'react'
import { P } from './index.styles'

const LinkText = ({
   children,
   ...rest
}: PropsWithChildren<{ size: number }>) => {
   return <P {...rest}>{children}</P>
}

export { LinkText }
