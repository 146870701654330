import { DefaultTheme } from 'styled-components'

const themeSettings: DefaultTheme = {
   colors: {
      primary: '#682994',
      primaryHover: '#7e4f9e',
      primaryActive: '#48156b',

      secondary: '#8f58c7',
      secondaryActive: '#6823ad',
      secondaryDisabled: '#c1abd6',

      grayDark: '#3d3d3d',
      grayMedium: '#7b7b7b',
      grayMediumLight: '#b8b8b8',
      grayLight: '#e0e0e0',
      white: '#FFF',

      layerBase: '#f8f8f8',
      layerSecond: '#f4f4f4',
      layerThird: '#f0f0f0',
      layerFourth: '#eee',
      layerBorder: '#d6d6d6',

      critical: '#f44336',
      warning: '#f5b04a',
      success: '#56ad45',
   },

   typography: {
      fontFamily: '"Roboto", sans-serif',
   },
   zIndex: {
      dropdown: 1000,
      sticky: 1020,
      fixed: 1030,
      modal: 1050,
      popover: 1060,
   },
   apps: {
      apiPlatform: '#9A47C9',
      eventsHub: '#EB7246',
      serviceMesh: '#52a37a',
      flexibleActions: '#853a79',
      users: '#3689B3',
   },
}

export { themeSettings }
