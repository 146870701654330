import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'

import { ThemeProvider } from 'sensedia-ui-kit'
import { themeSettings } from 'theme/themeSettings'

import 'locale/i18n'

ReactDOM.render(
   <ThemeProvider theme={themeSettings}>
      <App />
   </ThemeProvider>,
   document.getElementById('root'),
)
