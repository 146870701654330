export interface PoliciesRules {
   minimumLength: number
   requireUppercase: boolean
   requireLowercase: boolean
   requireNumbers: boolean
   requireSymbols: boolean
}

export interface PWPoliciesProps {
   password: string
   passwordPolicy: PoliciesRules
}

export interface RulesProps {
   lowercase: boolean
   uppercase: boolean
   numbers: boolean
   symbols: boolean
   size: boolean
}

export const paddingForm: React.CSSProperties = {
   padding: '14px 15px',
}

export const Lowercase = '[a-z]'

export const Uppercase = '[A-Z]'

export const Symbols = "[@!#~^$+=*.:;|_`,><'".concat('%&?"()}{\\/\\[\\]-]')

export const Numbers = '\\d'

export const initialRules: RulesProps = {
   lowercase: false,
   numbers: false,
   size: false,
   symbols: false,
   uppercase: false,
}

export const validateRule = (pass: string, regexString: string) => {
   const regex = new RegExp(`^(?=.*${regexString}).{1,}$`)
   return !!regex.exec(pass)
}
