import React from 'react'
import Layout from 'theme/layout'

import { getAliasDNS } from 'utils/resolvers'
import { ClientAccount, SignUp, NotFound } from 'views'
import { BrowserRouter, Switch, Route, RouteProps } from 'react-router-dom'

export default function Routes() {
   const tenantID = getAliasDNS()

   const allRoutes: ({ hasTenantId: boolean } & RouteProps)[] = [
      {
         hasTenantId: true,
         path: '/signup',
         exact: true,
         component: SignUp,
      },
      {
         hasTenantId: false,
         path: '/tenant-signin',
         exact: true,
         component: ClientAccount,
      },
   ]

   return (
      <BrowserRouter>
         <Layout>
            <Switch>
               {allRoutes.map(
                  ({ hasTenantId, ...route }, idx) =>
                     hasTenantId === !!tenantID && (
                        <Route {...route} key={`route-${idx}`} />
                     ),
               )}

               <Route path="*" exact component={NotFound} />
            </Switch>
         </Layout>
      </BrowserRouter>
   )
}
