import React, { useState } from 'react'

import { useFormik } from 'formik'
import { passwordRules } from 'config'
import { useTranslation } from 'react-i18next'
import { LinkText } from 'components/LinkText'
import { useAsync } from 'utils/hooks/useAsync'
import { ModalBody } from 'theme/layout/index.styles'
import { ResolveValidation } from './index.validation'
import { PasswordPolicies } from 'components/PasswordPolicies'
import { redirectTo, resolveRequestError } from 'utils/resolvers'
import { backToSignIn, BooleanFields, SignUpProps } from './index.resolvers'
import {
   Box,
   Button,
   Col,
   IconButton,
   Row,
   TextField,
   TextFormat,
   Toast,
} from 'sensedia-ui-kit'

export const SignUp = () => {
   const [t] = useTranslation()

   const token = new URLSearchParams(window.location.search).get('invite')

   const validationSchema = ResolveValidation(passwordRules)

   const [touch, setTouch] = useState<BooleanFields>({
      name: false,
      password: false,
      confirmPassword: false,
   })

   const [show, setShow] = useState<BooleanFields>({
      name: false,
      password: false,
      confirmPassword: false,
   })

   const initialValues: SignUpProps = {
      token: token || '',
      name: '',
      password: '',
      confirmPassword: '',
   }

   const form = useFormik({
      initialValues,
      validationSchema,
      validateOnMount: true,
      onSubmit: () => execute(),
   })

   const { execute, status } = useAsync(async () => {
      try {
         const { name, password, token } = form.values

         const settings: RequestInit = {
            method: 'POST',
            headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json',
            },
            credentials: 'omit',
            body: JSON.stringify({ name, password }),
         }

         const response: any = await fetch(
            `/signup/activate?invite=${token}`,
            settings,
         )

         if (response.status !== 201) throw response.json()

         redirectTo()

         Toast.success(t('alert-user-created'), 5000)
      } catch (e) {
         Toast.error(resolveRequestError(e), 5000)
         form.setSubmitting(false)
      }
   }, false)

   return (
      <ModalBody size={480}>
         <form onSubmit={form.handleSubmit}>
            <Row style={{ margin: 0 }}>
               <Col>
                  <TextFormat
                     size={16}
                     color="grayMedium"
                     align="center"
                     weight="bold"
                     data-testid="title-sign-up"
                  >
                     {t('sign-up-title')}
                  </TextFormat>
               </Col>

               <Col mt={5}>
                  <TextField
                     label={t('sign-up-field-name')}
                     name="name"
                     value={form.values.name}
                     onChange={form.handleChange}
                     onBlur={() => setTouch({ ...touch, name: true })}
                     autoComplete="off"
                     maxLength={100}
                     countChar={false}
                     data-testid="input-name"
                     error={form.errors.name}
                  />
               </Col>

               <Col mt={5}>
                  <TextField
                     label={t('sign-up-field-password')}
                     name="password"
                     type={show.password ? 'text' : 'password'}
                     value={form.values.password}
                     onChange={form.handleChange}
                     onBlur={() => setTouch({ ...touch, password: true })}
                     autoComplete="off"
                     maxLength={100}
                     data-testid="input-password"
                     error={form.errors.password}
                     countChar={false}
                     append={
                        <Box items="center">
                           <IconButton
                              type="button"
                              icon={show.password ? 'not-visible' : 'visible'}
                              onClick={() =>
                                 setShow({ ...show, password: !show.password })
                              }
                           />
                        </Box>
                     }
                  />
               </Col>

               <Col mt={5}>
                  <TextField
                     label={t('sign-up-field-confirm-password')}
                     name="confirmPassword"
                     type={show.confirmPassword ? 'text' : 'password'}
                     value={form.values.confirmPassword}
                     onChange={form.handleChange}
                     autoComplete="off"
                     maxLength={100}
                     data-testid="input-confirmPassword"
                     error={form.errors.confirmPassword}
                     countChar={false}
                     append={
                        <Box items="center">
                           <IconButton
                              type="button"
                              icon={
                                 show.confirmPassword
                                    ? 'not-visible'
                                    : 'visible'
                              }
                              onClick={() =>
                                 setShow({
                                    ...show,
                                    confirmPassword: !show.confirmPassword,
                                 })
                              }
                           />
                        </Box>
                     }
                  />
               </Col>

               <Col mt={10}>
                  <PasswordPolicies
                     password={form.values.password}
                     passwordPolicy={passwordRules}
                  />
               </Col>

               <Col mt={10}>
                  <Box justify="center">
                     <Button
                        variant="contained"
                        isLoading={status === 'pending'}
                        style={{ width: '100%' }}
                        disabled={Object.keys(form.errors).length > 0}
                        data-testid="btn-register"
                     >
                        {t('sign-up-button-sign-up')}
                     </Button>
                  </Box>
               </Col>

               <Col>
                  <Box justify="center">
                     <LinkText size={14} data-testid="text-return-sign-in">
                        {t('sign-up-button-to-go-sign-in')}&nbsp;
                        <span
                           data-testid="btn-return-sign-in"
                           onClick={backToSignIn}
                        >
                           {t('default-words-sign-in')}
                        </span>
                     </LinkText>
                  </Box>
               </Col>
            </Row>
         </form>
      </ModalBody>
   )
}
