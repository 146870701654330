import { redirectTo } from 'utils/resolvers'

export type SignUpProps = {
   token: string
   name: string
   password: string
   confirmPassword: string
}

export type BooleanFields = {
   name: boolean
   password: boolean
   confirmPassword: boolean
}

export const backToSignIn = () => {
   return redirectTo()
}
