import styled from 'styled-components'

export const Container = styled.div`
   width: 100%;
   min-height: 100vh;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
   padding: 15px;
`

export const ModalDialog = styled.div`
   max-width: 500px;
   background: #1d1224 0 0 no-repeat padding-box;
   border-radius: 18px;
   box-shadow: 0 4px 25px #230d31;
`

export const ModalContent = styled.div`
   min-width: 380px;
   overflow: hidden;
   border-radius: 18px;
   background: #1d1224 0 0 no-repeat padding-box;
   width: 100%;
   margin: 0px auto;
   border: 0px;
`

export const Banner = styled.div`
   min-height: 80px;
   padding: 10px;
   border-radius: 16px 16px 0 0;
   background-color: transparent;
   display: flex;
   justify-content: center;
   align-items: center;
`

export const Logo = styled.img`
   height: auto;
   cursor: pointer;

   width: 200px !important;
   max-width: 100%;
   max-height: 100%;
`

export const ModalBody = styled.div<{ size?: number }>`
   background: #fff;
   padding: 30px 40px;
   border-radius: 10px 10px 16px 16px;
   display: flex;
   justify-content: center;
   flex-direction: column;
   width: ${({ size }) => size || 400}px;
`
