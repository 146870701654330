import styled from 'styled-components'

export const P = styled.p<{ size: number }>`
   font-size: ${({ size }) => size}px;
   color: ${({ theme }) => theme.colors.grayDark};

   span {
      cursor: pointer;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
         color: ${({ theme }) => theme.colors.primaryHover};
      }
   }
`
