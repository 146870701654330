import { createGlobalStyle } from 'styled-components'

import 'react-perfect-scrollbar/dist/css/styles.css'
import { scrollBar } from './scrollBar/scrollBar'

const GlobalStyle = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Roboto:400,500,700&display=swap');

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  } 

  *:focus {
    outline: 0;
  }

  html {
    box-sizing: border-box;
  }

  html, body {
    height: 100%!important;
  }

  .container, #root {
    ${scrollBar}
  }

  .error-label {
    width: 100%!important;
  }

  body {
    font-family: ${(props) => props.theme.typography.fontFamily};
    color: ${(props) => props.theme.colors.grayDark};
    font-weight: 300;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    letter-spacing: .1px;
    text-rendering: optimizeLegibility;

    background-color: ${({ theme }) => theme.colors.grayDark};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;

    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
    overflow: hidden;
  }

  #root {
    width: 100%;
    min-height: 100vh;
    overflow: auto;
  }

  @media (max-width: 991px){
    body, html {
        overflow-x: auto;
    }
  }

  button {
    cursor: pointer;
    border: none;
    font-family: "Roboto",sans-serif!important;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary}
  }

  
  .row {
    > div{
        padding-right:10px;
        padding-left:10px;
      }
  }

  .ps__rail-y {
    z-index: 3;
  }

  ul {
    list-style-type: none;
  }
`

export default GlobalStyle
