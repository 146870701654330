import styled from 'styled-components'

export const Separator = styled.div<{ size: number }>`
   border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};
   width: ${({ size }) => size}px;
   position: absolute;
   left: -40px;

   &::after {
      content: 'or';
      position: absolute;
      top: -10px;
      background: ${({ theme }) => theme.colors.white};
      padding: 0px 10px;
      left: calc(50% - (32.66px / 2));
      font-size: 14px;
   }

   @media (max-width: 450px) {
      width: 100%;
      left: 0;
   }
`
