import styled from 'styled-components'
import { themeSettings } from 'theme/themeSettings'

export const ContainerRoles = styled.fieldset`
   border: 1px solid ${({ theme }) => theme.colors.grayLight};
   width: 100%;
   padding: 0 10px 10px;
   border-radius: 5px;
`

export const Legend = styled.legend`
   padding: 0.2em 0.5em;
   color: ${({ theme }) => theme.colors.grayMedium};
   font-size: 12px;
   text-align: left;
   position: relative;
   border-bottom: none !important;
   width: auto !important;
   margin-bottom: 0px !important;

   &::before {
      position: absolute;
      content: '';
      border-left: 1px solid ${({ theme }) => theme.colors.grayMediumLight};
      left: 0px;
      top: 7px;
   }

   &::after {
      position: absolute;
      content: '';
      border-right: 1px solid ${({ theme }) => theme.colors.grayMediumLight};
      right: 0px;
      top: 7px;
   }
`
export const IconBox = styled.div<{ color: keyof typeof themeSettings.colors }>`
   background: ${({ theme, color }) => theme.colors[color]};
   border-radius: 100%;
   width: 15px;
   height: 15px;
   margin-right: 10px;
   display: flex;

   & i {
      display: flex;
   }
`
