export const TextStyled: React.CSSProperties = {
   fontFamily: 'monospace',
   wordBreak: 'break-word',
}

export const noMargin: React.CSSProperties = {
   minHeight: 120,
}

export const paddingForm: React.CSSProperties = {
   padding: '14px 15px',
}
